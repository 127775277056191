<template>
  <section id="installers">
    <h2>Installer</h2>
    <nav class="seeders">
      <button class="btn primary-box" @click="doChanges">Update Database</button>
    </nav>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { dapatkanSemuaSurah, db } from '../firebase'
import { setDoc, doc, getDocs, collection } from 'firebase/firestore'

export default defineComponent({
  methods: {
    async doChanges() {
      //this.recalculateTasmiGradeAndXP()
      //this.berikanNomborPadaSurah()
    },
    async berikanNomborPadaSurah() {
      const semuaSurah = await dapatkanSemuaSurah()
      const semuaJanjiTambahNombor = semuaSurah.map(surah => {
        const id = parseInt(surah.id.replace(/s/, ""))
        return setDoc( doc(db, `surah/${surah.id}`), {id}, { merge: true })
      })
      await Promise.all(semuaJanjiTambahNombor)
    },
    async calculateDoneAyats() {
      
    },
    async recalculateTasmiGradeAndXP() {
      await this.$store.dispatch('kemaskiniSenaraiGred')
      this.senaraiGred = this.$store.state.senaraiGred

      await this.$store.dispatch('kemaskiniSenaraiLevel')
      this.senaraiLevel = this.$store.state.senaraiLevel

      let allPromises = []

      // get halaqah from firestore
      const semuaHalaqahSnap = await getDocs(collection(db, `halaqah`))
      // iterate through halaqah
      semuaHalaqahSnap.forEach(async halaqahSnap => {
        const halaqahId = halaqahSnap.id
        const halaqah = halaqahSnap.data()
        const semuaMuridSnap = await getDocs(collection(db, `halaqah/${halaqahId}/murid`))
        // iterate through murid
        semuaMuridSnap.forEach(async muridSnap => {
          const muridId = muridSnap.id
          const muridTakSort = muridSnap.data()
          const murid = Object.keys(muridTakSort).sort().reduce((murid, kekunci) => {
            murid[kekunci] = muridTakSort[kekunci]
            return murid
          }, {})

          murid.jumlahXP = 0
          murid.bilTasmi = 0

          // get tasmi if exists
          const semuaTasmiSnap = await getDocs(collection(db, `halaqah/${halaqahId}/murid/${muridId}/tasmi`))
          if (semuaTasmiSnap.size > 0) {
            // iterate through tasmi
            semuaTasmiSnap.forEach(tasmiSnap => {
              const tasmi = tasmiSnap.data()
              //console.log(`Tasmi bagi murid: ${murid.nama}`)

              // recalculate grade and XP
              tasmi.gredHafazan = this.beriGred(tasmi.kualitiHafazan, tasmi.skor)
              tasmi.xp = this.kiraXP(tasmi)

              // save to tasmi
              //const newTasmiSetDoc = setDoc(doc(db, `halaqah/${halaqahId}/murid/${muridId}/tasmi/${tasmi.ditasmiPada}`), tasmi, { merge: true })
              //allPromises.push(newTasmiSetDoc)
              //console.log(tasmi)

              // sum with jumlah xp
              murid.jumlahXP += tasmi.xp

              // increment bil tasmi
              murid.bilTasmi += 1

              console.log(`Murid: ${murid.nama} in tasmisnaploop`)
              console.log(murid)

            // end iterate tasmi
            })

          // end if tasmi exists
          }

          // calculate new level of murid
          const retrievedLevel = await this.updateLevelMurid(murid.jumlahXP)
          murid.level = {
            level: retrievedLevel.level,
            nama: retrievedLevel.nama
          }

          // save jumlah xp, bil tasmi, level to murid
          const newMuridSetDoc = setDoc(doc(db, `halaqah/${halaqahId}/murid/${muridId}`), murid, { merge: true })
          allPromises.push(newMuridSetDoc)
          console.log(murid)

          // increment bil murid
          halaqah.bilMurid += 1
        // end iterate murid
        })
        // save bil murid to halaqah
        //await setDoc(doc(db, `halaqah/${halaqahId}`), halaqah, { merge: true })
        //console.log(halaqah)
        await Promise.all(allPromises)
      //end iterate halaqah
      })
    },

    beriGred(kualitiHafazan, skor) {
      const senaraiGred = this.senaraiGred

      const gredIkutSkor = senaraiGred
        .sort((gred1, gred2) => (gred1.pointMula - gred2.pointMula))
        .filter(gred => skor >= gred.skorMin)
        .pop()

      const gredIkutKualitiHafazan = senaraiGred
        .sort((gred1, gred2) => (gred1.pointMula - gred2.pointMula) * -1)
        .filter(gred => (kualitiHafazan < gred.pointAkhir) || (kualitiHafazan == 100))
        .pop()
      
      const gredDiberi = gredIkutSkor.pointAkhir >= gredIkutKualitiHafazan ? gredIkutKualitiHafazan : gredIkutSkor;

      return gredDiberi.gred
    },

    kiraXP(tasmi) {
      const xpDaripadaSkor = tasmi.skor * 20
      const xpDaripadaKualitiHafazan = tasmi.kualitiHafazan

      const xpDaripadaGred = this.senaraiGred
        .sort((gred1, gred2) => (gred1.pointAkhir - gred2.pointAkhir) * -1)
        .filter(gred => gred.gred == tasmi.gredHafazan)
        .pop()
        .pointAkhir

      return xpDaripadaSkor + xpDaripadaKualitiHafazan + xpDaripadaGred
    },

    updateLevelMurid (xp) {
      const semuaLevel = this.$store.state.senaraiLevel
      return semuaLevel.filter(level => 
        (level.min <= xp) && (level.maks > xp)
      )[0]
    },
    
    async seedLevel() {
      const semuaLevel = this.seedInfo.level
      const janjiTambahLevel = Object.keys(semuaLevel).map(key => {
        return setDoc(doc(db, key), semuaLevel[key])
      })
      await Promise.all(janjiTambahLevel)
    },
    async seedGuru() {
      const semuaGuru = this.seedInfo.guru
      const janjiTambahGuru = Object.keys(semuaGuru).map(key => {
        return setDoc(doc(db, "users", key), semuaGuru[key])
      })
      await Promise.all(janjiTambahGuru)
    },
    async seedSekolah() {
      const semuaSekolah = this.seedInfo.sekolah
      const janjiTambahSekolah = Object.keys(semuaSekolah).map(key => {
        return setDoc(doc(db, "sekolah", key), semuaSekolah[key])
      })
      await Promise.all(janjiTambahSekolah)
    },
    async seedHalaqah() {
      const semuaHalaqah = this.seedInfo.halaqah
      const janjiTambahHalaqah = Object.keys(semuaHalaqah).map(key => {
        return setDoc(doc(db, "halaqah", key), semuaHalaqah[key])
      })
      await Promise.all(janjiTambahHalaqah)
    },
    async seedSurah() {
      const semuaSurah = this.seedInfo.surah
      const janjiTambahSurah = Object.keys(semuaSurah).map(key => {
        return setDoc(doc(db, `surah/${key}`), semuaSurah[key])
      })
      await Promise.all(janjiTambahSurah)
    },
    async seedAyat() {
      const semuaAyat = this.seedInfo.ayat
      const janjiTambahAyat = Object.keys(semuaAyat).map(key => {
        return setDoc(doc(db, key), semuaAyat[key])
      })
      await Promise.all(janjiTambahAyat)
    },
    async seedAyatNumbers() {
      const semuaAyat = this.seedInfo.ayat
      const janjiTambahAyat = Object.keys(semuaAyat).map(key => {
        const nombor = parseInt(key.match(/[0-9]{1,3}$/g)[0])
        //console.log(nombor)
        return setDoc(doc(db, key), { nombor }, { merge: true })
      })
      await Promise.all(janjiTambahAyat)
    },
    async seedMurid() {
      const semuaMurid = this.seedInfo.murid
      const janjiTambahMurid = Object.keys(semuaMurid).map(key => {
        return setDoc(doc(db, key), semuaMurid[key])
      })
      await Promise.all(janjiTambahMurid)
    },
  },
  data () {
    return {
    }
  }
})
</script>

<style lang="scss" scoped>
#installers {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;

  nav.seeders {
    display: grid;
    grid-template-columns: auto;
    width: 500px;

    > * {
      margin: 0.5rem 0;
    }
  }
}
</style>